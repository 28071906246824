import "animate.css";
import Brand from "../../assets/img/logos/swiss.svg";
import Actions from "../../components/actions/Actions";
import Empresa from "../empresa/Empresa";

function Swiss() {
  return (
    <Empresa brand={Brand}>
      <Actions
        firstButtonLabel='Comparar Cartillas'
        firstButtonUrl='https://www.swissmedical.com.ar/smgnewsite/pdf/prepaga/abm/abm.pdf'
      />
    </Empresa>
  );
}
export default Swiss;
