import Brand from "../../assets/img/buenosaires.jpg";
import Banner from "../../components/banner/Banner";

function Nosotros() {
  return (
    <div className='container mx-auto'>
      <Banner brand='Nosotros' />
      <div className='flex flex-col sm:flex-row'>
        <div className='p-4 mx-auto'>
          <img
            width='300px'
            className='rounded-xl'
            src={Brand}
            alt='Buenos Aires'
          />
        </div>
        <div className='sm:p-4 px-4'>
          <h3 className='text-2xl font-bold mb-6'>
            Somos una organización formada por profesionales ocupados en brindar
            asesoramiento a medida sobre salud.
          </h3>
          <p className='text-xl sm:text-2xl pb-4'>
            Entendemos que para cada persona existe un plan con la cobertura
            ideal.
            <br />
            Queremos indicarte cual es la que vos necesitas, sin pagar de más y
            permitiendo una cobertura completa de tus necesidades.
          </p>
          <p className='text-xl sm:text-2xl'>
            Nos distingue el compromiso por el seguimiento del producto que
            comercializamos.
            <br />
            Cumplimos 15 años apoyándonos en empresas líderes para garantizar un
            servicio postventa adecuado.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Nosotros;
