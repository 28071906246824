import whatsapp from "../../assets/img/whatsapp.svg";

function Whatsapp() {
  return (
    <div className='whatsapp flex flex-col items-center animate__animated animate__infinite animate__pulse'>
      <a
        href='https://wa.me/5491140680502?text=%C2%A1Hola!%20Ingres%C3%A9%20por%20la%20p%C3%A1gina%20para%20comunicarme%20con%20ustedes.'
        target='_blank'
        rel='noreferrer'
      >
        <img
          src={whatsapp}
          fetchpriority='low'
          className='mx-auto m-8'
          width={80}
          height={80}
          alt='Whatsapp Logo'
        />
      </a>
    </div>
  );
}

export default Whatsapp;
