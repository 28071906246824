import "animate.css";
import Brand from "../../assets/img/logos/Ras.png";
import Actions from "../actions/Actions";
import Empresa from "../empresa/Empresa";

function Ras() {
  return (
    <Empresa brand={Brand}>
      <Actions>
        <a
          href='https://ras.ar/wp-content/uploads/2024/06/RAScartilla_500_110624.pdf'
          target='_blank'
          rel='noreferrer'
        >
          <button className='group relative flex w-64 h-14 py-4 px-6 text-white bg-gradient-to-r flex from-sky-900 to-sky-400 items-center justify-center overflow-hidden rounded bg-neutral-950 font-bold transition hover:scale-105'>
            <div className='absolute inset-0 flex h-full w-full justify-center [transform:skew(-12deg)_translateX(-100%)] group-hover:duration-[1500ms] group-hover:[transform:skew(-12deg)_translateX(100%)]'>
              <div className='relative h-full w-8 bg-white/20'></div>
            </div>
            Cartilla Plan 500
          </button>
        </a>
        <a
          href='https://ras.ar/wp-content/uploads/2024/05/RAScartilla_1000_200524-1.pdf'
          target='_blank'
          rel='noreferrer'
        >
          <button className='group relative flex w-64 h-14 py-4 px-6 text-white bg-gradient-to-r flex from-sky-900 to-sky-400 items-center justify-center overflow-hidden rounded bg-neutral-950 font-bold transition hover:scale-105'>
            <div className='absolute inset-0 flex h-full w-full justify-center [transform:skew(-12deg)_translateX(-100%)] group-hover:duration-[1500ms] group-hover:[transform:skew(-12deg)_translateX(100%)]'>
              <div className='relative h-full w-8 bg-white/20'></div>
            </div>
            Cartilla Plan 1000
          </button>
        </a>
        <a
          href='https://ras.ar/wp-content/uploads/2024/05/RAScartilla_1500_200524.pdf'
          target='_blank'
          rel='noreferrer'
        >
          <button className='group relative flex w-64 h-14 py-4 px-6 text-white bg-gradient-to-r flex from-sky-900 to-sky-400 items-center justify-center overflow-hidden rounded bg-neutral-950 font-bold transition hover:scale-105'>
            <div className='absolute inset-0 flex h-full w-full justify-center [transform:skew(-12deg)_translateX(-100%)] group-hover:duration-[1500ms] group-hover:[transform:skew(-12deg)_translateX(100%)]'>
              <div className='relative h-full w-8 bg-white/20'></div>
            </div>
            Cartilla Plan 1500
          </button>
        </a>
        <a
          href='https://ras.ar/wp-content/uploads/2023/11/RAScartilla_FARMACIAS_061123.pdf'
          target='_blank'
          rel='noreferrer'
        >
          <button className='group relative flex w-64 h-14 py-4 px-6 text-white bg-gradient-to-r flex from-sky-900 to-sky-400 items-center justify-center overflow-hidden rounded bg-neutral-950 font-bold transition hover:scale-105'>
            <div className='absolute inset-0 flex h-full w-full justify-center [transform:skew(-12deg)_translateX(-100%)] group-hover:duration-[1500ms] group-hover:[transform:skew(-12deg)_translateX(100%)]'>
              <div className='relative h-full w-8 bg-white/20'></div>
            </div>
            Cartilla Farmacias
          </button>
        </a>
        <a
          href='https://ras.ar/wp-content/uploads/2023/11/RAScartilla_ODONTOLOGIA_271123.pdf'
          target='_blank'
          rel='noreferrer'
        >
          <button className='group relative flex w-64 h-14 py-4 px-6 text-white bg-gradient-to-r flex from-sky-900 to-sky-400 items-center justify-center overflow-hidden rounded bg-neutral-950 font-bold transition hover:scale-105'>
            <div className='absolute inset-0 flex h-full w-full justify-center [transform:skew(-12deg)_translateX(-100%)] group-hover:duration-[1500ms] group-hover:[transform:skew(-12deg)_translateX(100%)]'>
              <div className='relative h-full w-8 bg-white/20'></div>
            </div>
            Cartilla Odontología
          </button>
        </a>
      </Actions>
    </Empresa>
  );
}
export default Ras;
