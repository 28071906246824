function Actions({
  firstButtonLabel,
  firstButtonUrl,
  secondButtonLabel,
  secondButtonUrl,
  children,
}) {
  return (
    <div className='flex flex-col gap-4 select-none'>
      {firstButtonLabel && firstButtonUrl && (
        <a
          href={firstButtonUrl || "#"}
          target='_blank'
          rel='noreferrer noopener'
        >
          <button className='group relative flex w-64 h-14 py-4 px-6 text-white bg-gradient-to-r flex from-sky-900 to-sky-400 items-center justify-center overflow-hidden rounded bg-neutral-950 font-bold transition hover:scale-105'>
            <div className='absolute inset-0 flex h-full w-full justify-center [transform:skew(-12deg)_translateX(-100%)] group-hover:duration-[1500ms] group-hover:[transform:skew(-12deg)_translateX(100%)]'></div>
            {firstButtonLabel}
          </button>
        </a>
      )}
      {secondButtonLabel && secondButtonUrl && (
        <a
          href={secondButtonUrl || "#"}
          target='_blank'
          rel='noreferrer noopener'
        >
          <button className='group relative flex w-64 h-14 py-4 px-6 text-white bg-gradient-to-r flex from-sky-900 to-sky-400 items-center justify-center overflow-hidden rounded bg-neutral-950 font-bold transition hover:scale-105'>
            <div className='absolute inset-0 flex h-full w-full justify-center [transform:skew(-12deg)_translateX(-100%)] group-hover:duration-[1500ms] group-hover:[transform:skew(-12deg)_translateX(100%)]'></div>
            {secondButtonLabel}
          </button>
        </a>
      )}
      {children}
    </div>
  );
}

export default Actions;
