import Banner from "../../components/banner/Banner";
import Ras from "../../components/ras/Ras";
import Assistcard from "./../../components/assistcard/Assistcard";
import Cristal from "./../../components/cristal/Cristal";
import Ospoce from "./../../components/ospoce/Ospoce";
import Swiss from "./../../components/swiss/Swiss";

function Planes() {
  return (
    <div className='container mx-auto'>
      <Banner brand='Planes' />
      <div className='p-4'>
        <p className='text-2xl'>
          OSDI cuenta con una amplia selección de profesionales médicos y
          centros asistenciales distribuidos por todo el país a los cuales podrá
          acceder a través de nuestros diferentes planes.
        </p>
        <p className='text-2xl'>
          Contáctate ya mismo con nuestros asesores para conocer en profundidad
          el plan que mejor se adapte a tus necesidades.
        </p>
      </div>
      <div className='grid lg:grid-cols-2'>
        <div className='flex flex-col'>
          <Ospoce />
          <Swiss />
        </div>
        <div className='flex flex-col'>
          <Cristal />
          <Ras />
        </div>
      </div>
      <Assistcard />
    </div>
  );
}
export default Planes;
