import React, { useState } from "react";

function AccordionItem({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      role='button'
      className='button bg-gradient-to-r from-sky-100 to-sky-100 flex flex-col select-none cursor-pointer rounded-xl my-4'
      onClick={handleToggle}
    >
      <div className='flex items-center justify-center gap-4'>
        <h2 className='flex items-center p-4 text-2xl font-bold'>
          {isOpen ? "Ver menos" : "Ver más"}
        </h2>
        <span
          className={`transition duration-500 p-4 ${
            isOpen ? "-rotate-0" : "rotate-90"
          }`}
        >
          {
            <svg
              width='15'
              height='15'
              viewBox='0 0 15 15'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className='h-5 w-5'
            >
              <path
                d='M7.5 2C7.77614 2 8 2.22386 8 2.5L8 11.2929L11.1464 8.14645C11.3417 7.95118 11.6583 7.95118 11.8536 8.14645C12.0488 8.34171 12.0488 8.65829 11.8536 8.85355L7.85355 12.8536C7.75979 12.9473 7.63261 13 7.5 13C7.36739 13 7.24021 12.9473 7.14645 12.8536L3.14645 8.85355C2.95118 8.65829 2.95118 8.34171 3.14645 8.14645C3.34171 7.95118 3.65829 7.95118 3.85355 8.14645L7 11.2929L7 2.5C7 2.22386 7.22386 2 7.5 2Z'
                fill='currentColor'
                fillRule='evenodd'
                clipRule='evenodd'
              ></path>
            </svg>
          }
        </span>
      </div>
      <div
        className={
          isOpen &&
          "flex justify-center items-center p-4 animate__animated animate__fadeIn"
        }
      >
        {isOpen && children}
      </div>
    </div>
  );
}

function Accordion({ children }) {
  return <AccordionItem>{children}</AccordionItem>;
}

export default Accordion;
