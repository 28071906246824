import "animate.css";
import Brand from "../../assets/img/logos/Cristal.png";
import Actions from "../../components/actions/Actions";
import Empresa from "../empresa/Empresa";

function Cristal() {
  return (
    <Empresa brand={Brand}>
      <Actions
        firstButtonLabel='Cartilla Zafiro'
        firstButtonUrl='https://obrasocialdigital.com.ar/compare/zafiro.pdf'
        secondButtonLabel='Cartilla Esmeralda'
        secondButtonUrl='https://obrasocialdigital.com.ar/compare/esmeralda.pdf'
      />
    </Empresa>
  );
}
export default Cristal;
