import "animate.css";
import Brand from "../../assets/img/logos/AssistCardBlack.png";

function Assistcard() {
  return (
    <section className='my-4 select-none'>
      <div className='container mx-auto flex flex-col md:flex-row w-100'>
        <div className='w-full flex justify-center items-center px-4 my-4'>
          <div>
            <img
              width='200px'
              className='mx-auto mb-2 select-none'
              src={Brand}
              alt='Logo'
            />
            <div>
              <p className='text-2xl px-2 sm:w-1/2 my-4 mx-auto'>
                Contá con la mejor cobertura de asistencia al viajero. ASSIST
                CARD es la compañía líder mundial en asistencia integral al
                viajero.
              </p>
              <h2 className='text-2xl px-2 sm:w-1/2 my-4 mx-auto'>
                <strong>Comenzá hoy con tu seguro</strong>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Assistcard;
