import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Carousel } from "react-responsive-carousel";
import "./_homeCarousel.scss";

import unoD from "../../assets/img/carousel/unoD.jpg";
import unoM from "../../assets/img/carousel/unoM.jpg";

function HomeCarousel() {
  return (
    <Carousel
      swipeable={true}
      showArrows={false}
      showStatus={false}
      showIndicators={false}
      showThumbs={false}
      emulateTouch={true}
      infiniteLoop={true}
      autoPlay={true}
    >
      <div className=''>
        <img
          rel='preload'
          fetchpriority='high'
          as='image'
          className='d-block'
          srcSet={`${unoM} 600w,
          ${unoD} 1500w`}
          src={unoD}
          alt='30% de descuento'
        />
      </div>
    </Carousel>
  );
}

export default HomeCarousel;
