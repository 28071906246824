import "animate.css";

import Banner from "../../components/banner/Banner";

const Sucursales = () => {
  const ContactInfo = ({ name, phone, email }) => {
    const whatsappLink = `https://wa.me/${phone.replace(/[^0-9]/g, "")}`;

    return (
      <div className='bg-white border rounded-xl max-w-40 p-4 my-4 transition'>
        <p className='font-bold text-2xl'>{name}</p>
        <a
          href={whatsappLink}
          target='_blank'
          rel='noopener noreferrer'
          className='text-blue-500 montserrat text-xl hover:underline'
        >
          {phone}
        </a>
        <p className=' montserrat'>{email}</p>
      </div>
    );
  };

  return (
    <div className='container mx-auto'>
      <Banner brand='Sucursales' />
      <div className='text-center p-4'>
        <div>
          <h3 className='font-bold text-2xl'>CABA</h3>
          <p className='text-xl'>Viamonte 611</p>
        </div>
        <ContactInfo
          phone='+54 9 11 2453-1156'
          email='Sergiogonzalezvarela@hotmail.com'
          name='Sergio Gonzalez'
        />
        <ContactInfo
          phone='+54 9 11 4047-1417'
          email='Felipemsbroker@gmail.com'
          name='Felipe Gonzalez'
        />
        <div>
          <h3 className='font-bold text-2xl'>ZONA OESTE</h3>
          <p className='text-xl'>Alvarado 333</p>
        </div>
        <ContactInfo
          phone='+54 9 11 5906-1077'
          email='Fernandamsbroker@gmail.com'
          name='Fernanda Miraglio'
        />
      </div>
    </div>
  );
};

export default Sucursales;
