import "animate.css";
import Brand from "../../assets/img/logos/Ospoce.png";
import Actions from "../../components/actions/Actions";
import Empresa from "../empresa/Empresa";

function Ospoce() {
  return (
    <Empresa brand={Brand}>
      <Actions
        firstButtonLabel='Comparar Cartillas'
        firstButtonUrl='https://cartilla.ospoceintegral.com.ar/cartilla_integral.php'
        secondButtonLabel='Comparar Sanatorios'
        secondButtonUrl='https://www.msbroker.com.ar/assets/CUADROS-SANATORIOS-COMPARATIVOS-OSPOCE-INTEGRAL-2024.pdf'
      />
    </Empresa>
  );
}
export default Ospoce;
