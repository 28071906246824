import { useRouteError } from "react-router-dom";

function Error() {
  const error = useRouteError();
  console.error(error);

  return (
    <div className='bg-gradient-to-r from-sky-200 to-sky-200 p-4 text-center'>
      <h1 className='text-4xl'>
        <strong>Ups!</strong>
      </h1>
      <p className='text-2xl'>Te pedimos disculpas, hubo un error.</p>
    </div>
  );
}

export default Error;
