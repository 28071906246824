import classNames from "classnames";
import { useState } from "react";
import { NavLink } from "react-router-dom";

import Logo from "../../logo/Logo";

import "./_menuTW.scss";

function MenuTW() {
  const toggleMenu = () => {
    setMenuOpen(!menuState);
  };

  const [menuState, setMenuOpen] = useState(true);

  return (
    <>
      <div
        className='mx-auto flex flex-nowrap py-2 justify-between'
        translate='no'
      >
        <div className='mx-4 sm:mx-auto flex flex-nowrap items-center justify-between'>
          <NavLink to='/'>
            <Logo />
          </NavLink>
        </div>
        <button
          onClick={toggleMenu}
          type='button'
          className='md:hidden text-white focus:outline-none focus:ring-2 focus:ring-sky-300 rounded-lg inline-flex items-center justify-center mr-4 transition-all'
        >
          <span className='sr-only'>Open main menu</span>
          <svg
            className='w-6 h-6'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z'
              clip-rule='evenodd'
            ></path>
          </svg>
          <svg
            className='hidden w-6 h-6'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
              clipRule='evenodd'
            ></path>
          </svg>
        </button>
      </div>
      <hr className='h-px bg-gray-200 border-0 dark:bg-sky-200'></hr>
      <nav
        translate='no'
        className={classNames(
          "menu flex-row justify-center sm:flex md:py-2 md:px-5 to-sky-400 transition-all",
          {
            "opacity-0 md:opacity-100": menuState,
            "md:opacity-100": !menuState,
          }
        )}
      >
        <div
          id='mobile-menu'
          className={classNames("md:block w-full md:w-auto transition-all", {
            hidden: menuState,
            "opacity-0 md:opacity-100": menuState,
            "md:opacity-100": !menuState,
          })}
        >
          <ul className='flex-col md:flex-row flex md:space-x-8 gap-y-2 py-2 md:py-0 px-2 md:mt-0 md:text-sm md:font-medium transition-all'>
            <li>
              <NavLink to='/'>
                <p>INICIO</p>
              </NavLink>
            </li>
            <li>
              <NavLink to='/planes'>
                <p>PLANES</p>
              </NavLink>
            </li>
            <li>
              <NavLink to='/sucursales'>
                <p>SUCURSALES</p>
              </NavLink>
            </li>
            <li>
              <NavLink to='/nosotros'>
                <p>NOSOTROS</p>
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default MenuTW;
