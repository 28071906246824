import "animate.css";
import { NavLink } from "react-router-dom";
import HomeCarousel from "../../components/carousel/HomeCarousel";
import Whatsapp from "../../components/whatsapp/Whatsapp";
import "./_home.scss";

function Home() {
  return (
    <div className='home text-center'>
      <div className='mx-auto sm:w-2/3'>
        <HomeCarousel />
      </div>
      <div className=' flex flex-col justify-items-center p-4'>
        <h1 className='title font-bold my-4'>
          Formá parte de la salud del futuro.
        </h1>
        <h2 className='text-2xl montserrat'>
          Afiliate en el día de manera gratuita y desde tu casa.
        </h2>
        <h3 className='text-2xl font-bold montserrat'>
          Nosotros nos encargamos de todo.
        </h3>
        <Whatsapp />
      </div>
      <div className=' container mx-auto lg:flex lg:justify-evenly p-4'>
        <NavLink to='/planes'>
          <button className='button flex w-full lg:w-60 justify-between text-2xl bg-gradient-to-r from-sky-100 to-sky-100 select-none cursor-pointer rounded-xl p-4 mb-4'>
            <strong>PLANES</strong>
            <div className='my-auto'>
              <span className='flex px-2 -rotate-90'>
                <svg
                  width='15'
                  height='15'
                  viewBox='0 0 15 15'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                >
                  <path
                    d='M7.5 2C7.77614 2 8 2.22386 8 2.5L8 11.2929L11.1464 8.14645C11.3417 7.95118 11.6583 7.95118 11.8536 8.14645C12.0488 8.34171 12.0488 8.65829 11.8536 8.85355L7.85355 12.8536C7.75979 12.9473 7.63261 13 7.5 13C7.36739 13 7.24021 12.9473 7.14645 12.8536L3.14645 8.85355C2.95118 8.65829 2.95118 8.34171 3.14645 8.14645C3.34171 7.95118 3.65829 7.95118 3.85355 8.14645L7 11.2929L7 2.5C7 2.22386 7.22386 2 7.5 2Z'
                    fill='currentColor'
                    fillRule='evenodd'
                    clipRule='evenodd'
                  ></path>
                </svg>
              </span>
            </div>
          </button>
        </NavLink>
        <NavLink to='/nosotros'>
          <button className='button flex w-full lg:w-60 justify-between text-2xl bg-gradient-to-r from-sky-100 to-sky-100 select-none cursor-pointer rounded-xl p-4 mb-4'>
            <strong>NOSOTROS</strong>
            <div className='my-auto'>
              <span className='flex px-2 -rotate-90'>
                <svg
                  width='15'
                  height='15'
                  viewBox='0 0 15 15'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                >
                  <path
                    d='M7.5 2C7.77614 2 8 2.22386 8 2.5L8 11.2929L11.1464 8.14645C11.3417 7.95118 11.6583 7.95118 11.8536 8.14645C12.0488 8.34171 12.0488 8.65829 11.8536 8.85355L7.85355 12.8536C7.75979 12.9473 7.63261 13 7.5 13C7.36739 13 7.24021 12.9473 7.14645 12.8536L3.14645 8.85355C2.95118 8.65829 2.95118 8.34171 3.14645 8.14645C3.34171 7.95118 3.65829 7.95118 3.85355 8.14645L7 11.2929L7 2.5C7 2.22386 7.22386 2 7.5 2Z'
                    fill='currentColor'
                    fillRule='evenodd'
                    clipRule='evenodd'
                  ></path>
                </svg>
              </span>
            </div>
          </button>
        </NavLink>
      </div>
    </div>
  );
}
export default Home;
