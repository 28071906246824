import "animate.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Brands from "../brands/Brands";
import "./_banner.scss";

function Banner({ brand }) {
  return (
    <div className="container banner flex flex-row justify-evenly items-center mx-auto mt-3">
      {brand ? (
        <div className="flex flex-row mx-auto w-1/3 items-center">
          <h2 className="mx-auto font-bold text-xl underline" alt={brand}>
            {brand}
          </h2>
        </div>
      ) : (
        <div className="flex flex-row mx-auto w-1/3 md:w-1/6 items-center">
          <Brands />
        </div>
      )}
    </div>
  );
}

export default Banner;
