import vgl from "../../../assets/img/vgl.png";

function Footer() {
  return (
    <footer className='mt-auto'>
      <div className='flex flex-row sm:w-3/4 sm:justify-evenly pb-4 pt-12 mt-12 px-6 mx-auto items-center'>
        <p className='opacity-75 text-s me-4'>
          <strong>© OSDI 2024</strong>
        </p>
        <a href='https://vgl.com.ar' target='_blank' rel='noreferrer'>
          <img
            fetchpriority='low'
            src={vgl}
            className='w-6 mx-auto'
            alt='VGL Logo'
          />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
